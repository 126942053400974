import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Import the CSS file
import Logo from '../icon/icon.svg';
const Navbar = () => {
  // Function to close the collapsed menu
  const closeMenu = () => {
    const navbarToggle = document.getElementById('navbarToggle');
    if (navbarToggle && window.getComputedStyle(navbarToggle).display !== 'none') {
      navbarToggle.click();
    }
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        {/* Your logo */}
        <Link className="navbar-brand" to="/" onClick={closeMenu}>
        <img src={Logo} alt="Logo" className='Nav-Logo'/> 
        </Link>

        {/* Toggler button for small screens */}
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>


        {/* Navbar items and links */}
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item home-icon-text">
              <div className='title'>
              <h1 className="title-text">
                Delta
              </h1>
              </div>
            </li>
          </ul>

          {/* Additional links moved to the end */}
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link underline zoom" to="/apply-jobs" onClick={closeMenu}>
                Apply Jobs
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link underline zoom" to="/talent-community" onClick={closeMenu}>
                Join Our Talent Community
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link underline zoom" to="/eeo-accommodations" onClick={closeMenu}>
                EEO/Accommodations
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link underline zoom" to="/deltapilot" onClick={closeMenu}>
                Delta Pilot
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link underline zoom" to="/register" onClick={closeMenu}>
                Register
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link underline zoom" to="/login" onClick={closeMenu}>
                Log In
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
